import React from 'react'
import styled from 'styled-components'

import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

const StyledGallery = styled.section`
  background: rgba(50, 50, 50, 0.05);
  mix-blend-mode: normal;
  background-blend-mode: normal;
`

const Content = styled(ParseContent)`
  & h2 {
    font-size: ${(props) => props.theme.font.size['40']};
    line-height: 45px;

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size['25']};
      line-height: 30px;

      & br {
        display: none;
      }
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;

  .gatsby-image-wrapper img {
    border-radius: 15px;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    & > div {
      margin-bottom: 15px;
    }
  }

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(4fr 1fr);
    gap: 15px;
    grid-template-rows: repeat(2, auto);

    & > div:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    & > div:nth-child(2),
    & > div:nth-child(3) {
      & img {
        height: 253px;
      }
    }

    & > div:nth-child(4) {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
      height: 404px;
      width: 518px;

      & img {
        height: 404px;
        width: 518px;
        object-fit: cover;
      }
    }

    & > div:nth-child(5) {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 2;
      height: 196px;

      & img {
        height: 196px;
      }
    }

    & > div:nth-child(6) {
      grid-column-start: 2;
      grid-row-start: 2;
      height: 196px;
      margin-top: 208px;

      & img {
        height: 196px;
      }
    }

    & > div:last-child {
      grid-column-start: 3;
      grid-column-end: 5;
      margin-top: -235px;

      & .gatsby-image-wrapper,
      & img {
        height: 100%;
      }
    }
  }
`

interface GalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}

const Gallery: React.FC<GalleryProps> = ({ fields }) => {
  const images = fields?.images?.slice(0, 7)
  const [photoIndex, setPhotoIndex] = React.useState(0)
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <StyledGallery id="gallery">
        <div className="container py-lg-5">
          <div className="row py-5">
            <div className="col-12 text-center">
              <Content content={fields?.description} />
            </div>
          </div>
          <div className="row pb-5 mb-lg-5 mx-0 mx-lg-n3">
            <ImageContainer>
              {images?.map((image, index) => (
                <div key={index}>
                  <Plaatjie
                    image={image}
                    alt=""
                    onClick={() => setIsOpen(true)}
                  />
                </div>
              ))}
            </ImageContainer>
          </div>
        </div>
      </StyledGallery>

      {/* {(console.log(fields?.images[photoIndex + 1].sourceUrl), 'field')} */}

      {isOpen && (
        <>
          <Lightbox
            mainSrc={fields?.images[photoIndex].sourceUrl}
            nextSrc={
              fields?.images[(photoIndex + 1) % fields?.images?.length]
                .sourceUrl
            }
            prevSrc={
              fields?.images[
                (photoIndex + fields?.images?.length) % fields?.images?.length
              ].sourceUrl
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + fields?.images?.length - 1) %
                  fields?.images?.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % fields?.images?.length)
            }
          />
        </>
      )}
    </>
  )
}

export default Gallery
